import { grey } from '@mui/material/colors';
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @typedef HealthDefinition
 * @property {string} label
 * @property {string} color
 * @property {string} fail_reason
 */

/**
 * @type {import("react").FC<{ health: HealthDefinition }>}
 */
const EntityHealthIndicator = ({ health }) => {
  const label = health?.label || 'None';
  const color = health?.color || grey[500];
  const reason =
    health?.fail_reason || 'Entity does not satisfy any health definition.';

  return (
    <Tooltip title={`${label}: ${reason}`} PopperProps={{ placement: 'right' }}>
      <CircleIcon fontSize="small" sx={{ color }} />
    </Tooltip>
  );
};
EntityHealthIndicator.propTypes = {
  // @ts-ignore
  health: PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    fail_reason: PropTypes.string.isRequired,
  }).isRequired,
};

export default EntityHealthIndicator;
