// keyPath is used for accessing the dynamic configurable label or path from the LABEL_CONFIG file using getLabelPathFromLabelConfig util function
// Do not change anything in this file, if you are changing make sure you understand the impact of it.
// Mainly do not change the key part of the object as those are keys used everywhere.
export const DEFAULT_ROUTE_CONFIG = {
  modelInventory: {
    path: 'my-model',
    keyPath: 'MENUS.modelInventory',
  },
  inventory: {
    path: 'inventory',
    keyPath: 'MENUS.inventory',
  },
  archivedModels: {
    path: 'my-model/archived',
    keyPath: 'MENUS.modelInventory.subPaths.archivedModels',
  },
  createModel: {
    path: 'my-model/add-model',
    keyPath: 'MENUS.modelInventory.subPaths.createModel',
  },
  editModel: {
    path: 'my-model/:uniqueId',
    keyPath: 'MENUS.modelInventory.subPaths.editModel',
  },
  dashboard: {
    keyPath: 'MENUS.dashboard',
    path: 'dashboard',
  },
  bulkUpload: {
    path: 'bulk-upload',
    keyPath: 'MENUS.bulkUpload',
  },
  bulkTransition: {
    path: 'bulk-transition',
    keyPath: 'MENUS.bulkTransition',
  },
  bulkUpdate: {
    path: 'bulk-update',
    keyPath: 'MENUS.bulkUpdate',
  },
  bulkDocumentUpload: {
    path: 'bulk-document',
    keyPath: 'MENUS.bulkDocumentUpload',
  },
  ModelAssociation: {
    path: 'model-artifact',
    keyPath: 'MENUS.ModelAssociation',
  },
  archivedArtifacts: {
    path: 'model-artifact/archived',
    keyPath: 'MENUS.ModelAssociation.subPaths.archivedArtifacts',
  },
  allArtifacts: {
    path: 'model-artifact/archived',
    keyPath: 'MENUS.ModelAssociation.subPaths.allArtifacts',
  },
  editModelAssociation: {
    path: 'model-artifact/:uniqueId',
    keyPath: 'MENUS.ModelAssociation.subPaths.editModelAssociation',
  },
  DocumentInventory: {
    path: 'documents',
    keyPath: 'MENUS.DocumentInventory',
  },
  workflow: {
    path: 'workflows',
    keyPath: 'MENUS.workflow',
  },
  editWorkflow: {
    path: 'workflows/edit-workflow',
    keyPath: 'MENUS.workflow.subPaths.editWorkflow',
  },
  templateList: {
    path: 'templates',
    keyPath: 'MENUS.templateList',
  },
  createTemplate: {
    path: 'templates/create-template',
    keyPath: 'MENUS.templateList.subPaths.createTemplate',
  },
  editTemplate: {
    path: 'templates/edit-template',
    keyPath: 'MENUS.templateList.subPaths.editTemplate',
  },
  globalAttributes: {
    path: 'attributes',
    keyPath: 'MENUS.globalAttributes',
  },
  rules: {
    path: 'rules',
    keyPath: 'MENUS.rules',
  },
  report: {
    path: 'report',
    keyPath: 'MENUS.report',
  },
  userManagement: {
    path: 'user-management',
    keyPath: 'MENUS.userManagement',
  },
  appManagement: {
    path: 'app-management',
    keyPath: 'MENUS.appManagement',
  },
  documentInventory: {
    path: 'documents',
    keyPath: 'MENUS.documentInventory',
  },

  documentTemplate: {
    path: 'document-template',
    keyPath: 'MENUS.documentTemplate',
  },
  configuration: {
    path: 'configuration',
    keyPath: 'MENUS.Configuration',
  },
  bulkActions: {
    path: 'bulk-actions',
    keyPath: 'MENUS.bulkActions',
  },
  dynamicChart: {
    path: 'dynamic-chart',
    keyPath: 'MENUS.dynamicChart',
  },
  automation: {
    path: 'automation',
    keyPath: 'MENUS.automation',
  },
  createAutomation: {
    path: 'automation/create-automation',
    keyPath: 'MENUS.automation.createAutomation',
  },
  updateAutomation: {
    path: 'automation/update-automation',
    keyPath: 'MENUS.automation.updateAutomation',
  },
  themeAndAppearance: {
    path: 'theme-appearance',
    keyPath: 'MENUS.themeAndAppearance',
  },
  notAuthorized: {
    path: 'not-authorized',
    keyPath: 'MENUS.notAuthorized',
  },
  modelRequest: {
    path: 'assessment',
    keyPath: 'MENUS.modelRequest',
  },
  editModelRequest: {
    path: 'assessment/:uniqueId',
    keyPath: 'MENUS.modelRequest.subPaths.editModelRequest',
  },
  accessRequest: {
    path: 'accessRequest',
    keyPath: 'MENUS.accessRequest',
  },
  editAccessRequest: {
    path: 'accessRequest/:uniqueId',
    keyPath: 'MENUS.accessRequest.subPaths.editAccessRequest',
  },
  useCase: {
    path: 'useCase',
    keyPath: 'MENUS.useCase',
  },
  editUseCase: {
    path: 'useCase/:uniqueId',
    keyPath: 'MENUS.useCase.subPaths.editUseCase',
  },
  emailTemplate: {
    path: 'email-template',
    keyPath: 'MENUS.emailTemplate',
  },
  administrativeOptions: {
    path: 'administrative-options',
    keyPath: 'MENUS.administrativeOptions',
  },
  helpAndSupport: {
    path: 'help',
    keyPath: 'MENUS.helpAndSupport',
  },
};
