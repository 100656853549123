// This file is for testing team for testing this feature

const modelInventoryFeatureLabel = 'ModelNew';
const modelAssociationFeatureLabel = 'ArtifactNew';
const modelRequestFeatureLabel = 'AssessmentNew';
const useCaseFeatureLabel = 'Use Case New';
const accessRequestFeatureLabel = 'Access Request New';

// This is our default file, do not edit anything. Please create new config file for different tenants
// Do not try to edit the key part of the object as it is having dependencies everywhere, make sure you understand the code before editing this file.
export const LABEL_CONFIG_DYNAMIC_LABELING_TEST = {
  MENUS: {
    dashboard: {
      label: 'DashboardNew',
      path: 'dashboardNew',
    },
    inventory: {
      label: 'InventoryNew',
      path: 'inventoryNew',
    },
    modelInventory: {
      label: 'My Models New',
      featureLabel: modelInventoryFeatureLabel, // If you are changing this change it in the template type as well
      path: 'my-model-new', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        createModel: {
          label: 'Add Model New',
          path: 'my-model-new/add-model-new',
        },
        editModel: {
          label: 'Edit Model New',
          path: 'my-model-new/:uniqueId',
        },
        archivedModels: {
          label: 'Archived Models New',
          path: 'my-model-new/archived-new',
        },
      },
    },
    modelRequest: {
      label: 'AssessmentsNew',
      featureLabel: modelRequestFeatureLabel, // If you are changing this change it in the template type as well
      path: 'assessments-new', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editModelRequest: {
          label: 'Edit Assessment New',
          path: 'assessments-new/:uniqueId',
        },
      },
    },
    useCase: {
      label: 'Use Cases New',
      featureLabel: useCaseFeatureLabel, // If you are changing this change it in the template type as well
      path: 'useCases-new', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editUseCase: {
          label: 'Edit useCase New',
          path: 'useCases-new/:uniqueId',
        },
      },
    },
    ModelAssociation: {
      label: 'Model Artifacts New',
      featureLabel: modelAssociationFeatureLabel, // If you are changing this change it in the template type as well
      path: 'model-artifact-new', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        allArtifacts: {
          label: 'All Artifacts New',
          path: 'model-artifact-new',
        },
        editModelAssociation: {
          label: 'Edit Artifact New',
          path: 'model-artifact-new/:uniqueId',
        },
        archivedArtifacts: {
          label: 'Archived Artifacts New',
          path: 'model-artifact-new/archived-new',
        },
      },
    },
    DocumentInventory: {
      label: 'DocumentsNew',
      path: 'documents-new',
    },
    rules: {
      label: 'RulesNew',
      path: 'rules-new',
    },
    report: {
      label: 'ReportsNew',
      path: 'reports-new',
    },
    bulkActions: {
      label: 'Bulk Actions New',
      path: 'bulk-actions-new',
    },
    bulkUpload: {
      label: 'Bulk Upload new',
      path: 'bulk-upload-new',
    },
    bulkTransition: {
      label: 'Bulk Transition New',
      path: 'bulk-transition New',
    },
    bulkUpdate: {
      label: 'Bulk Update New',
      path: 'bulk-update-new',
    },
    bulkDocumentUpload: {
      label: 'Bulk Document Upload',
      path: 'bulk-document',
    },
    Configuration: {
      label: 'Configuration New',
      path: 'configuration-new',
    },
    globalAttributes: {
      label: 'Attributes New',
      path: 'attributes New',
    },
    workflow: {
      label: 'Workflows New',
      featureLabel: 'Workflow New',
      path: 'workflows-new', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editWorkflow: {
          label: 'Edit Workflow New',
          path: 'workflows-new/edit-workflow-new',
        },
      },
    },
    templateList: {
      label: 'Templates New',
      path: 'templates-new', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        createTemplate: {
          label: 'Create Template New',
          path: 'templates-new/create-template-new',
        },
        editTemplate: {
          label: 'Edit Template',
          path: 'templates-new/edit-template-new',
        },
      },
    },
    documentTemplate: {
      label: 'Document Template New',
      path: 'document-template-new',
    },
    dynamicChart: {
      label: 'Dynamic Chart New',
      path: 'dynamic-chart-new',
    },
    automation: {
      label: 'Automation New',
      path: 'automation-new', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        createAutomation: {
          label: 'Create Automation New',
          path: 'automation-new/create-automation-new',
        },
        updateAutomation: {
          label: 'Update Automation New',
          path: 'automation-new/update-automation-new',
        },
      },
    },
    themeAndAppearance: {
      label: 'Theme and Appearance New',
      path: 'theme-and-appearance-new',
    },
    emailTemplate: {
      label: 'Email Template New',
      path: 'email-template-new',
    },
    helpAndSupport: {
      label: 'Help and Support New',
      path: 'help-new',
    },
    administrativeOptions: {
      label: 'Administrative Options New',
      path: 'administrative-options-new',
    },
    userManagement: {
      label: 'User Management New',
      path: 'user-management-new',
    },
    appManagement: {
      label: 'App Management New',
      path: 'app-management-new',
    },
    documentInventory: {
      label: 'Documents New',
      path: 'documents-new',
    },
    accessRequest: {
      label: 'Access Request New',
      featureLabel: accessRequestFeatureLabel, // If you are changing this change it in the template type as well
      path: 'accessRequest-new', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editAccessRequest: {
          label: 'Edit Access Request New',
          path: 'accessRequest-new/:uniqueId',
        },
      },
    },
  },
  LABELS: {
    modelInventory: {
      otherDetails: {
        label: 'Other Details',
      },
    },
  },
  // If you are changing this part, then make sure you are changing the paths and menus labels to these.
  TEMPLATE_TYPES: {
    ModelInventory: {
      label: 'ModelInventoryNew',
      featureLabel: modelInventoryFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    ModelAssociation: {
      label: 'ModelAssociationNew',
      featureLabel: modelAssociationFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    ModelRequest: {
      label: 'Assessment New',
      featureLabel: modelRequestFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    AccessRequest: {
      label: 'Access Request New',
      featureLabel: accessRequestFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    UseCase: {
      label: 'Use Case New',
      featureLabel: useCaseFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
  },
};
