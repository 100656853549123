// @ts-nocheck
import { LABEL_CONFIG } from '../const/LabelConfigs/labelConfig';
import { LABEL_CONFIG_DYNAMIC_LABELING_TEST } from '../const/LabelConfigs/labelConfigDynamicLabelingTest';
import { LABEL_CONFIG_FCRA } from '../const/LabelConfigs/labelConfigFCRA';

const TENANTS_MAPPING_FOR_LABEL_CONFIG = {
  fcra_vault: LABEL_CONFIG_FCRA,
  dynamic_labeling: LABEL_CONFIG_DYNAMIC_LABELING_TEST,
};

export const getLabelConfigFile = () => {
  const ORG = () => {
    let org;
    if (window.CONFIG.KEYCLOAK_LOGIN) {
      org = localStorage.getItem('REALM_ID');
    } else {
      org = JSON.parse(localStorage.getItem('profile_data'))?.org;
    }
    return org;
  };

  const currentTenant = ORG();

  if (TENANTS_MAPPING_FOR_LABEL_CONFIG[currentTenant]) {
    return TENANTS_MAPPING_FOR_LABEL_CONFIG[currentTenant];
  }
  return LABEL_CONFIG;
};
