const modelInventoryFeatureLabel = 'Assessment';
const modelAssociationFeatureLabel = 'Artifact';
const modelRequestFeatureLabel = 'Product';
const useCaseFeatureLabel = 'Use Case';
const accessRequestFeatureLabel = 'Access Request';

export const LABEL_CONFIG_FCRA = {
  MENUS: {
    dashboard: {
      label: 'Dashboard',
      path: 'dashboard',
    },
    inventory: {
      label: 'Inventory',
      path: 'inventory',
    },
    modelInventory: {
      label: 'Assessments',
      featureLabel: modelInventoryFeatureLabel, // If you are changing this change it in the template type as well
      path: 'assessments', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        createModel: {
          label: 'Add Assessment',
          path: 'assessments/add-assessment',
        },
        editModel: {
          label: 'Edit Assessment',
          path: 'assessments/:uniqueId',
        },
        archivedModels: {
          label: 'Archived Models',
          path: 'assessments/archived',
        },
      },
    },
    modelRequest: {
      label: 'Products',
      featureLabel: modelRequestFeatureLabel, // If you are changing this change it in the template type as well
      path: 'products', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editModelRequest: {
          label: 'Edit Product',
          path: 'products/:uniqueId',
        },
      },
    },
    useCase: {
      label: 'Use Cases',
      featureLabel: useCaseFeatureLabel, // If you are changing this change it in the template type as well
      path: 'useCases', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editUseCase: {
          label: 'Edit useCase',
          path: 'useCases/:uniqueId',
        },
      },
    },
    ModelAssociation: {
      label: 'Artifacts',
      featureLabel: modelAssociationFeatureLabel, // If you are changing this change it in the template type as well
      path: 'artifacts', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        allArtifacts: {
          label: 'All Artifacts',
          path: 'artifacts',
        },
        editModelAssociation: {
          label: 'Edit Artifact',
          path: 'artifacts/:uniqueId',
        },
        archivedArtifacts: {
          label: 'Archived Artifacts',
          path: 'artifacts/archived',
        },
      },
    },
    DocumentInventory: {
      label: 'Documents',
      path: 'documents',
    },
    rules: {
      label: 'Rules',
      path: 'rules',
    },
    report: {
      label: 'Reports',
      path: 'reports',
    },
    bulkActions: {
      label: 'Bulk Upload',
      path: 'bulk-upload',
    },
    bulkDocumentUpload: {
      label: 'Bulk Document Upload',
      path: 'bulk-document',
    },
    Configuration: {
      label: 'Configuration',
      path: 'configuration',
    },
    globalAttributes: {
      label: 'Attributes',
      path: 'attributes',
    },
    workflow: {
      label: 'Workflows',
      featureLabel: 'Workflow',
      path: 'workflows', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editWorkflow: {
          label: 'Edit Workflow',
          path: 'workflows/edit-workflow',
        },
      },
    },
    templateList: {
      label: 'Templates',
      path: 'templates', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        createTemplate: {
          label: 'Create Template',
          path: 'templates/create-template',
        },
        editTemplate: {
          label: 'Edit Template',
          path: 'templates/edit-template',
        },
      },
    },
    documentTemplate: {
      label: 'Document Template',
      path: 'document-template',
    },
    dynamicChart: {
      label: 'Dynamic Chart',
      path: 'dynamic-chart',
    },
    automation: {
      label: 'Automation',
      path: 'automation', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        createAutomation: {
          label: 'Create Automation',
          path: 'automation/create-automation',
        },
        updateAutomation: {
          label: 'Update Automation',
          path: 'automation/update-automation',
        },
      },
    },
    themeAndAppearance: {
      label: 'Theme and Appearance',
      path: 'theme-and-appearance',
    },
    emailTemplate: {
      label: 'Email Template',
      path: 'email-template',
    },
    helpAndSupport: {
      label: 'Help and Support',
      path: 'help',
    },
    administrativeOptions: {
      label: 'Administrative Options',
      path: 'administrative-options',
    },
    userManagement: {
      label: 'User Management',
      path: 'user-management',
    },
    accessRequest: {
      label: 'Access Request',
      featureLabel: accessRequestFeatureLabel, // If you are changing this change it in the template type as well
      path: 'accessRequest', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editAccessRequest: {
          label: 'Edit Access Request',
          path: 'accessRequest/:uniqueId',
        },
      },
    },
  },
  LABELS: {
    modelInventory: {
      otherDetails: {
        label: 'Questionnaire',
      },
    },
  },
  // If you are changing this part, then make sure you are changing the paths and menus labels to these.
  TEMPLATE_TYPES: {
    ModelInventory: {
      label: 'Assessment',
      featureLabel: modelInventoryFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    ModelAssociation: {
      label: 'Artifact',
      featureLabel: modelAssociationFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    ModelRequest: {
      label: 'Product',
      featureLabel: modelRequestFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    AccessRequest: {
      label: 'Access Request',
      featureLabel: accessRequestFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    UseCase: {
      label: 'Use Case',
      featureLabel: useCaseFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
  },
};
