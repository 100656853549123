// @ts-nocheck
import React from 'react';
import { flatMap } from 'lodash';

import NotFound404 from './components/Common/NotFound404';
import { getLabelPathFromLabelConfig, reactLazyRetry } from './utils/Utils';
import SuspenseComponent from './components/Common/SuspenseComponent';
import ErrorBoundary from './components/Common/ErrorBoundary';
import ArchivedEntity from './views/ModelInventory/ArchivedEntity';
import EmailTemplate from './views/Configuration/EmailTemplate/EmailTemplate';
import { DEFAULT_ROUTE_CONFIG } from './const/defaultRoutesConfig';

const Layout = reactLazyRetry(() => import('./views/Layout/Layout'));
const Login = reactLazyRetry(() => import('./views/Login/Login'));
const Account = reactLazyRetry(() => import('./views/Login/Account'));
const Organization = reactLazyRetry(() => import('./views/Login/Organization'));

const HelpAndSupport = reactLazyRetry(
  () => import('./views/HelpAndSupport/HelpAndSupport')
);
const ThemeAppearance = reactLazyRetry(
  () => import('./views/Configuration/ThemeAppearance/ThemeAppearance')
);
const UserManagement = reactLazyRetry(
  () => import('./views/UserManagement/UserManagement')
);
const AppManagement = reactLazyRetry(
  () => import('./views/AppManagement/AppManagement')
);

const ModelInventory = reactLazyRetry(
  () => import('./views/ModelInventory/ModelInventory')
);
const ModelInventoryCreateModel = reactLazyRetry(
  () => import('./views/ModelInventory/CreateModelStepper')
);
const Dashboard = reactLazyRetry(
  () => import('./views/ModelInventory/Dashboard')
);
const BulkUpload = reactLazyRetry(
  () => import('./views/BulkActions/BulkUpload/BulkUpload')
);
const BulkTransition = reactLazyRetry(
  () => import('./views/BulkActions/BulkTransition/BulkTransition')
);
const BulkUpdate = reactLazyRetry(
  () => import('./views/BulkActions/BulkUpdate/BulkUpdate')
);
const BulkDocumentUpload = reactLazyRetry(
  () => import('./views/BulkActions/BulkDocumentUpload/BulkDocumentUpload')
);
const ModelAssociation = reactLazyRetry(
  () => import('./views/ModelAssociation/ModelAssociation')
);
const DocumentInventory = reactLazyRetry(
  () => import('./views/DocumentInventory/DocumentInventory')
);
const EditModelAssociation = reactLazyRetry(
  () => import('./views/ModelAssociation/EditModelAssociation')
);
const ModelValidation = reactLazyRetry(
  () => import('./views/ModelAssociation/ModelValidation')
);
const EditTemplate = reactLazyRetry(
  () => import('./views/Configuration/Template/EditTemplateView')
);
const TemplateList = reactLazyRetry(
  () => import('./views/Configuration/TemplateList')
);
const GlobalAttributes = reactLazyRetry(
  () => import('./views/Configuration/GlobalAttributes')
);
const EditModelDetails = reactLazyRetry(
  () => import('./views/ModelInventory/EditModelDetails')
);
const WorkFlowList = reactLazyRetry(
  () => import('./views/Configuration/Workflow/WorkFlowList')
);
const EditWorkFlow = reactLazyRetry(
  () => import('./views/Configuration/Workflow/EditWorkFlow')
);
const Rules = reactLazyRetry(() => import('./views/RulesAndReports/Rules'));

const Reports = reactLazyRetry(() => import('./views/RulesAndReports/Reports'));
const DocumentTemplate = reactLazyRetry(
  () => import('./views/Configuration/Documentation/DocumentTemplate')
);
const DynamicChart = reactLazyRetry(
  () => import('./views/Configuration/DynamicChart/DynamicChart')
);
const AutomationList = reactLazyRetry(
  () => import('./views/Configuration/Automation/AutomationList')
);
const CreateAutomation = reactLazyRetry(
  () => import('./views/Configuration/Automation/CreateAutomation')
);
const NotAuthorized = reactLazyRetry(
  () => import('./components/Common/NotAuthorized')
);

const ModelRequest = reactLazyRetry(
  () => import('./views/ModelInventory/ModelRequest/ModelRequest')
);
const EditModelRequest = reactLazyRetry(
  () => import('./views/ModelInventory/ModelRequest/EditModelRequest')
);
const AccessRequest = reactLazyRetry(
  () => import('./views/ModelInventory/AccessRequest/AccessRequest')
);

const EditAccessRequest = reactLazyRetry(
  () => import('./views/ModelInventory/AccessRequest/EditAccessRequest')
);

const UseCaseList = reactLazyRetry(
  () => import('./views/ModelInventory/UseCase/UseCaseList')
);
const EditUseCase = reactLazyRetry(
  () => import('./views/ModelInventory/UseCase/EditUseCase')
);

const Routes = [
  {
    name: 'Model Inventory',
    path: DEFAULT_ROUTE_CONFIG?.modelInventory?.path || 'my-model',
    key: 'modelInventory',
    element: <ModelInventory />,
    permission: 'ModelInventory_modelInventory',
    children: [
      {
        name: 'Archived Models',
        path: DEFAULT_ROUTE_CONFIG?.archivedModels?.path || 'my-model/archived',
        key: 'archivedModels',
        element: <ArchivedEntity />,
        permission: 'ModelInventory_modelInventory',
      },
      {
        name: 'Add Model',
        path: DEFAULT_ROUTE_CONFIG?.createModel?.path || 'my-model/add-model',
        key: 'createModel',
        element: <ModelInventoryCreateModel />,
        permission: 'ModelInventory_modelInventory',
      },
      {
        name: 'Edit Model ',
        key: 'editModel',
        path: DEFAULT_ROUTE_CONFIG?.editModel?.path || 'my-model/:uniqueId',
        element: <EditModelDetails />,
      },
    ],
  },
  {
    name: 'Dashboard',
    key: 'dashboard',
    path: DEFAULT_ROUTE_CONFIG?.dashboard?.path || 'dashboard',
    element: <Dashboard />,
    permission: 'ModelInventory_dashboard',
  },
  {
    name: 'Bulk Upload',
    key: 'bulkUpload',
    path: DEFAULT_ROUTE_CONFIG?.bulkUpload?.path || 'bulk-upload',
    element: <BulkUpload />,
    permission: 'BulkActions_bulkUpload',
  },
  {
    name: 'Bulk Transition',
    key: 'bulkTransition',
    path: DEFAULT_ROUTE_CONFIG?.bulkTransition?.path || 'bulk-transition',
    element: <BulkTransition />,
    permission: 'BulkActions_bulkTransition',
  },
  {
    name: 'Bulk Update',
    key: 'bulkUpdate',
    path: DEFAULT_ROUTE_CONFIG?.bulkUpdate?.path || 'bulk-update',
    element: <BulkUpdate />,
    permission: 'BulkActions_bulkTransition',
  },
  {
    name: 'Bulk Document Upload',
    key: 'bulkDocumentUpload',
    path: DEFAULT_ROUTE_CONFIG?.bulkDocumentUpload?.path || 'bulk-document',
    element: <BulkDocumentUpload />,
    permission: 'BulkActions_bulkTransition',
  },
  {
    key: 'ModelAssociation',
    path: DEFAULT_ROUTE_CONFIG?.ModelAssociation?.path || 'model-artifact',
    element: <ModelAssociation />,
    permission: 'ModelAssociation',
    children: [
      {
        name: 'Archived Artifacts',
        path:
          DEFAULT_ROUTE_CONFIG?.archivedArtifacts?.path ||
          'model-artifact/archived',
        key: 'archivedArtifacts',
        element: <ArchivedEntity />,
        permission: 'ModelAssociation',
      },
      {
        key: 'editModelAssociation',
        path:
          DEFAULT_ROUTE_CONFIG?.editModelAssociation?.path ||
          'model-artifact/:uniqueId',
        element: <EditModelAssociation />,
      },
    ],
  },
  {
    path: 'model-validation',
    element: <ModelValidation />,
  },
  {
    key: 'workflow',
    path: DEFAULT_ROUTE_CONFIG?.workflow?.path || 'workflows',
    element: <WorkFlowList />,
    permission: 'Configuration_workflow',
    children: [
      {
        key: 'editWorkflow',
        path:
          DEFAULT_ROUTE_CONFIG?.editWorkflow?.path || 'workflows/edit-workflow',
        element: <EditWorkFlow />,
      },
    ],
  },
  {
    key: 'templateList',
    path: DEFAULT_ROUTE_CONFIG?.templateList?.path || 'templates',
    element: <TemplateList />,
    permission: 'Configuration_templateList',
    children: [
      {
        key: 'createTemplate',
        path:
          DEFAULT_ROUTE_CONFIG?.createTemplate?.path ||
          'templates/create-template',
        element: <EditTemplate />,
      },
      {
        key: 'editTemplate',
        path:
          DEFAULT_ROUTE_CONFIG?.editTemplate?.path || 'templates/edit-template',
        element: <EditTemplate />,
      },
    ],
  },
  {
    key: 'globalAttributes',
    path: DEFAULT_ROUTE_CONFIG?.globalAttributes?.path || 'attributes',
    element: <GlobalAttributes />,
    permission: 'Configuration_globalAttributes',
  },
  {
    key: 'rules',
    path: DEFAULT_ROUTE_CONFIG?.rules?.path || 'rules',
    element: <Rules />,
    permission: 'RulesReports_rules',
  },
  {
    key: 'report',
    path: DEFAULT_ROUTE_CONFIG?.report?.path || 'report',
    element: <Reports />,
    permission: 'RulesReports_report',
  },
  {
    key: 'userManagement',
    path: DEFAULT_ROUTE_CONFIG?.userManagement?.path || 'user-management',
    element: <UserManagement />,
    permission: 'AdministrativeOptions_userManagement',
  },
  {
    key: 'appManagement',
    path: DEFAULT_ROUTE_CONFIG?.appManagement?.path || 'app-management',
    element: <AppManagement />,
    permission: 'AdministrativeOptions_appManagement',
  },
  {
    name: 'Document Inventory',
    key: 'DocumentInventory',
    path: DEFAULT_ROUTE_CONFIG?.documentInventory?.path || 'document-inventory',
    element: <DocumentInventory />,
    permission: 'DocumentInventory_documentInventory',
  },
  {
    name: 'Document Template',
    key: 'DocumentTemplate',
    path: DEFAULT_ROUTE_CONFIG?.documentTemplate?.path || 'document-template',
    element: <DocumentTemplate />,
    permission: 'Configuration_documentTemplate',
  },
  {
    name: 'Dynamic Chart',
    key: 'dynamicChart',
    path: DEFAULT_ROUTE_CONFIG?.dynamicChart?.path || 'dynamic-chart',
    element: <DynamicChart />,
    permission: 'Configuration_dynamicChart',
  },
  {
    name: 'Automation',
    key: 'automation',
    path: DEFAULT_ROUTE_CONFIG?.automation?.path || 'automation',
    element: <AutomationList />,
    permission: 'Configuration_automation',
    children: [
      {
        name: 'Create Automation',
        key: 'createAutomation',
        path:
          DEFAULT_ROUTE_CONFIG?.createAutomation?.path ||
          'automation/create-automation',
        element: <CreateAutomation />,
        permission: 'Configuration_automation',
      },
      {
        name: 'Update Automation',
        key: 'updateAutomation',
        path:
          DEFAULT_ROUTE_CONFIG?.updateAutomation?.path ||
          'automation/update-automation',
        element: <CreateAutomation />,
        permission: 'Configuration_automation',
      },
    ],
  },
  {
    name: 'Theme and Appearance',
    key: 'themeAndAppearance',
    path: DEFAULT_ROUTE_CONFIG?.themeAndAppearance?.path || 'theme-appearance',
    element: <ThemeAppearance />,
    permission: 'Configuration_themeAndAppearance',
  },
  {
    name: ' NotAuthorized',
    key: 'notAuthorized',
    path: 'not-authorized',
    element: <NotAuthorized />,
  },
  {
    name: 'Assessment',
    path: DEFAULT_ROUTE_CONFIG?.modelRequest?.path || 'assessment',
    key: 'modelRequest',
    element: <ModelRequest />,
    permission: 'ModelInventory_modelRequest',
    children: [
      {
        name: 'Edit Assessment',
        key: 'editModelRequest',
        path:
          DEFAULT_ROUTE_CONFIG?.editModelRequest?.path ||
          'assessment/:uniqueId',
        element: <EditModelRequest />,
      },
    ],
  },
  {
    name: 'Access Request',
    path: DEFAULT_ROUTE_CONFIG?.accessRequest?.path || 'accessRequest',
    key: 'accessRequest',
    element: <AccessRequest />,
    // permission: 'ModelInventory_accessRequest',
    children: [
      {
        name: 'Access Request',
        key: 'editAccessRequest',
        path:
          DEFAULT_ROUTE_CONFIG?.editAccessRequest?.path ||
          'accessRequest/:uniqueId',
        element: <EditAccessRequest />,
      },
    ],
  },
  {
    name: 'UseCase',
    path: DEFAULT_ROUTE_CONFIG?.useCase?.path || 'useCase',
    key: 'useCase',
    element: <UseCaseList />,
    permission: 'ModelInventory_useCase',
    children: [
      {
        name: 'Edit useCase',
        key: 'editUseCase',
        path: DEFAULT_ROUTE_CONFIG?.editUseCase?.path || 'useCase/:uniqueId',
        element: <EditUseCase />,
      },
    ],
  },
  {
    name: 'Email Template',
    key: 'EmailTemplate',
    path: DEFAULT_ROUTE_CONFIG?.emailTemplate?.path || 'email-template',
    element: <EmailTemplate />,
    permission: 'Configuration_emailTemplate',
  },
];

/**
 * @type {import('react-router-dom').RouteObject[]}
 * */
export const publicRoutes = [
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/login/:accountId',
    element: <Login />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/account',
    element: <Account />,
    errorElement: <ErrorBoundary />,
  },

  {
    path: '/org',
    element: <Organization />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '*',
    element: <NotFound404 />,
    errorElement: <ErrorBoundary />,
  },
];

const mapRoutes = (routes, userPermissions, parentKey = '') =>
  routes.map(({ path, element, permission, key, children }) => {
    const fullKey = parentKey ? `${parentKey}.${key}` : `MENUS.${key}`;
    const routePath = getLabelPathFromLabelConfig(fullKey, 'path', path);

    const mappedRoute = {
      path: routePath || path,
      element:
        userPermissions?.[permission] === 'None' ? (
          <NotAuthorized />
        ) : (
          <SuspenseComponent>{element}</SuspenseComponent>
        ),
      errorElement: <ErrorBoundary />,
    };

    if (children && children.length) {
      mappedRoute.children = mapRoutes(children, userPermissions, fullKey);
    }

    return mappedRoute;
  });

const flattenRoutes = (routes) =>
  flatMap(routes, (route) => {
    const { children, ...rest } = route;
    return children ? [rest, ...flattenRoutes(children)] : rest;
  });

/**
 * @param {Record<string, 'None' | 'View' | 'Manage'>} userPermissions User Permissions returned from API
 * @returns {import('react-router-dom').RouteObject[]}
 */
export const getProtectedRoutes = (userPermissions) => [
  {
    path: '/',
    element: <Layout />,
    children: flattenRoutes(mapRoutes(Routes, userPermissions)),
  },
  {
    path: '/help',
    element: (
      <SuspenseComponent>
        <HelpAndSupport />
      </SuspenseComponent>
    ),
    errorElement: <ErrorBoundary />,
  },
];
