/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useCallback, useState } from 'react';
import { Stack, styled } from '@mui/material';
import {
  AddPhotoAlternate,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  InsertLink,
  LinkOff,
  Redo,
  TableChart,
  Undo,
  WebAsset,
  YouTube,
} from '@mui/icons-material';
import { Editor } from '@tiptap/react';
import PropTypes from 'prop-types';

import useSolyticsEditorToolbar, {
  TEXT_STYLE_OPTIONS,
  FONT_SIZES,
} from './useSolyticsEditorToolbar';
import toolbarItems from './toolbarItems';
import ImageUploadModal from './modals/UploadImageModal';
import {
  ToggleButton,
  ToolbarBaseButton,
  ToolbarDropdown,
  ToolbarMenuItem,
  ToolbarVerticalDivider,
} from './components';
import TableBubbleMenu from './menus/TableBubbleMenu';
import ResizableImageBubbleMenu from './menus/ResizableImageBubbleMenu';
import IFrameModal from './modals/IFrameModal';
import LinkModal from './modals/LinkModal';
import { useSolyticsEditorContext } from './context/SolyticsEditorContext';

const SolyticsEditorToolbarRoot = styled(Stack, {
  name: 'SolyticsEditorToolbar',
  slot: 'root',
})(({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: '#ffffff',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  flexWrap: 'wrap',
  border: '1px solid #e7e4e2',
  borderRadius: 8,
  position: 'sticky',
  top: '80px',
  zIndex: theme.zIndex.fab,
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
}));

/**
 * @typedef SolyticsEditorToolbarProps
 * @property {import('@tiptap/react').Editor} editor
 */

/**
 * Editor toolbar component
 * @type {import('react').FC<SolyticsEditorToolbarProps & import('@mui/material').StackProps>}
 */
const SolyticsEditorToolbar = ({ editor, ...props }) => {
  const { hiddenToolbarItems } = useSolyticsEditorContext();
  const {
    handleToggle,
    canRedo,
    canUndo,
    undo,
    redo,
    getActiveTextStyle,
    getCurrentFont,
    addImage,
    insertTable,
    handleTextAlign,
  } = useSolyticsEditorToolbar(editor);
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);
  const [iframeModalOpen, setIframeModalOpen] = useState(false);
  const [youtubeModalOpen, setYoutubeModalOpen] = useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [currentLink, setCurrentLink] = useState('');

  const handleTextStyleChange = useCallback(
    (e) => {
      const textStyle = e?.target?.value;
      if (textStyle === 0) {
        handleToggle('paragraph');
      } else {
        handleToggle('heading', {
          level: textStyle,
        });
      }
    },
    [handleToggle]
  );

  const handleToggleUploadImageModal = useCallback(
    () => setUploadImageModalOpen((prev) => !prev),
    [setUploadImageModalOpen]
  );

  const handleInsertTable = useCallback(
    () => insertTable({ cols: 2, rows: 1, withHeaderRow: false }),
    [insertTable]
  );

  const getTextAlignHandler = useCallback(
    (alignment) => {
      return () => handleTextAlign(alignment);
    },
    [handleTextAlign]
  );

  const handleInsertYoutubeVideo = useCallback(
    (url) => {
      if (url) {
        editor.commands.setYoutubeVideo({
          src: url,
        });
      }
    },
    [editor]
  );

  const handleInsertIframe = useCallback(
    (url) => {
      if (url) {
        editor.chain().focus().setIframe({ src: url }).run();
      }
    },
    [editor]
  );

  const handleInsertSetLink = useCallback(
    (url) => {
      if (!url) {
        editor.chain().focus().unsetLink().run();
      }
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    },
    [editor]
  );

  const handleToggleYoutubeModal = useCallback(
    () => setYoutubeModalOpen((prev) => !prev),
    [setYoutubeModalOpen]
  );
  const handleToggleIFrameModal = useCallback(
    () => setIframeModalOpen((prev) => !prev),
    [setIframeModalOpen]
  );

  const handleToggleLinkModal = useCallback(() => {
    const url = editor.getAttributes('link')?.href;
    setCurrentLink(url);
    setLinkModalOpen((prev) => !prev);
  }, [setLinkModalOpen, editor, setCurrentLink, setLinkModalOpen]);

  const handleSetFontSize = useCallback(
    (e) => {
      editor?.chain()?.focus()?.setFontSize(e?.target?.value)?.run();
    },
    [editor]
  );

  const renderToggleButton = (item) => {
    return (
      <ToggleButton
        key={item.action}
        action={item.action}
        editor={editor}
        handleToggle={handleToggle}
        title={`Toggle ${item.action}`}
        aria-label={`Toggle ${item.action}`}
      >
        {item.buttonContent}
      </ToggleButton>
    );
  };

  const renderDivider = () => {
    return (
      <div style={{ display: 'flex', padding: '4px' }}>
        <ToolbarVerticalDivider />
      </div>
    );
  };

  const renderUndoButton = () => {
    return (
      <ToolbarBaseButton onClick={undo} disabled={!canUndo}>
        <Undo sx={{ fontSize: 16 }} />
      </ToolbarBaseButton>
    );
  };

  const renderRedoButton = () => {
    return (
      <ToolbarBaseButton onClick={redo} disabled={!canRedo}>
        <Redo sx={{ fontSize: 16 }} />
      </ToolbarBaseButton>
    );
  };

  const renderHeadingDropdown = () => {
    return (
      <ToolbarDropdown
        value={getActiveTextStyle()}
        onChange={handleTextStyleChange}
        select
        fullWidth={false}
        SelectProps={{
          MenuProps: {
            slotProps: {
              paper: {
                sx: {
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                },
              },
            },
          },
        }}
      >
        {TEXT_STYLE_OPTIONS.map((textStyle) => (
          <ToolbarMenuItem key={textStyle?.value} value={textStyle?.value}>
            {textStyle?.label}
          </ToolbarMenuItem>
        ))}
      </ToolbarDropdown>
    );
  };

  const renderImageButton = () => {
    return (
      <ToolbarBaseButton onClick={handleToggleUploadImageModal}>
        <AddPhotoAlternate sx={{ fontSize: 18 }} />
      </ToolbarBaseButton>
    );
  };

  const renderTableButton = () => {
    return (
      <ToolbarBaseButton onClick={handleInsertTable}>
        <TableChart sx={{ fontSize: 18 }} />
      </ToolbarBaseButton>
    );
  };

  const renderTextAlignControls = () => {
    return (
      <>
        <ToggleButton
          editor={editor}
          action={{ textAlign: 'left' }}
          onClick={getTextAlignHandler('left')}
        >
          <FormatAlignLeft fontSize="small" />
        </ToggleButton>
        <ToggleButton
          editor={editor}
          action={{ textAlign: 'center' }}
          onClick={getTextAlignHandler('center')}
        >
          <FormatAlignCenter fontSize="small" />
        </ToggleButton>
        <ToggleButton
          editor={editor}
          action={{ textAlign: 'right' }}
          onClick={getTextAlignHandler('right')}
        >
          <FormatAlignRight fontSize="small" />
        </ToggleButton>
        <ToggleButton
          editor={editor}
          action={{ textAlign: 'justify' }}
          onClick={getTextAlignHandler('justify')}
        >
          <FormatAlignJustify fontSize="small" />
        </ToggleButton>
      </>
    );
  };

  const renderYouTubeButton = () => {
    return (
      <ToolbarBaseButton onClick={handleToggleYoutubeModal}>
        <YouTube sx={{ fontSize: 18 }} />
      </ToolbarBaseButton>
    );
  };

  const renderIFrameButton = () => {
    return (
      <ToolbarBaseButton onClick={handleToggleIFrameModal}>
        <WebAsset sx={{ fontSize: 18 }} />
      </ToolbarBaseButton>
    );
  };

  const renderLinkButton = () => {
    return (
      <ToolbarBaseButton onClick={handleToggleLinkModal}>
        {editor.isActive('link') ? (
          <LinkOff sx={{ fontSize: 18 }} />
        ) : (
          <InsertLink sx={{ fontSize: 18 }} />
        )}
      </ToolbarBaseButton>
    );
  };

  const renderFontSizeSelect = () => {
    return (
      <ToolbarDropdown
        value={getCurrentFont()}
        onChange={handleSetFontSize}
        select
        fullWidth={false}
        sx={{
          width: '100px',
        }}
        SelectProps={{
          MenuProps: {
            slotProps: {
              paper: {
                sx: {
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                },
              },
            },
          },
        }}
      >
        {FONT_SIZES.map((font) => (
          <ToolbarMenuItem key={font} value={font}>
            {font}
          </ToolbarMenuItem>
        ))}
      </ToolbarDropdown>
    );
  };

  const renderToolbarItem = (item) => {
    const renderFunction = {
      toggle: () => renderToggleButton(item),
      divider: renderDivider,
      undo: renderUndoButton,
      redo: renderRedoButton,
      heading: renderHeadingDropdown,
      image: renderImageButton,
      table: renderTableButton,
      youtube: renderYouTubeButton,
      iframe: renderIFrameButton,
      textAlign: renderTextAlignControls,
      link: renderLinkButton,
      fontSize: renderFontSizeSelect,
    };

    if (Object.keys(renderFunction).includes(item.type)) {
      return renderFunction[item.type]();
    }
    return null;
  };

  return (
    <SolyticsEditorToolbarRoot {...props}>
      {toolbarItems
        .filter((item) => !hiddenToolbarItems.includes(item.type))
        .map((item) => renderToolbarItem(item))}
      <ImageUploadModal
        open={uploadImageModalOpen}
        onClose={handleToggleUploadImageModal}
        onConfirm={addImage}
      />
      <TableBubbleMenu editor={editor} />
      <ResizableImageBubbleMenu editor={editor} />
      <IFrameModal
        mode="youtube"
        open={youtubeModalOpen}
        onClose={handleToggleYoutubeModal}
        onSubmit={handleInsertYoutubeVideo}
      />
      <IFrameModal
        mode="iframe"
        open={iframeModalOpen}
        onClose={handleToggleIFrameModal}
        onSubmit={handleInsertIframe}
      />
      <LinkModal
        open={linkModalOpen}
        onSubmit={handleInsertSetLink}
        onClose={handleToggleLinkModal}
        currentURL={currentLink}
      />
    </SolyticsEditorToolbarRoot>
  );
};
SolyticsEditorToolbar.propTypes = {
  editor: PropTypes.instanceOf(Editor).isRequired,
};

export default SolyticsEditorToolbar;
