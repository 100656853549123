const modelInventoryFeatureLabel = 'Model';
const modelAssociationFeatureLabel = 'Artifact';
const modelRequestFeatureLabel = 'Assessment';
const useCaseFeatureLabel = 'Use Case';
const accessRequestFeatureLabel = 'Access Request';

// This is our default file, do not edit anything. Please create new config file for different tenants
// Do not try to edit the key part of the object as it is having dependencies everywhere, make sure you understand the code before editing this file.
export const LABEL_CONFIG = {
  MENUS: {
    dashboard: {
      label: 'Dashboard',
      path: 'dashboard',
    },
    inventory: {
      label: 'Inventory',
      path: 'inventory',
    },
    modelInventory: {
      label: 'My Models',
      featureLabel: modelInventoryFeatureLabel, // If you are changing this change it in the template type as well
      path: 'my-model', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        createModel: {
          label: 'Add Model',
          path: 'my-model/add-model',
        },
        editModel: {
          label: 'Edit Model',
          path: 'my-model/:uniqueId',
        },
        archivedModels: {
          label: 'Archived Models',
          path: 'my-model/archived',
        },
      },
    },
    modelRequest: {
      label: 'Assessments',
      featureLabel: modelRequestFeatureLabel, // If you are changing this change it in the template type as well
      path: 'assessments', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editModelRequest: {
          label: 'Edit Assessment',
          path: 'assessments/:uniqueId',
        },
      },
    },
    useCase: {
      label: 'Use Cases',
      featureLabel: useCaseFeatureLabel, // If you are changing this change it in the template type as well
      path: 'useCases', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editUseCase: {
          label: 'Edit useCase',
          path: 'useCases/:uniqueId',
        },
      },
    },
    ModelAssociation: {
      label: 'Model Artifacts',
      featureLabel: modelAssociationFeatureLabel, // If you are changing this change it in the template type as well
      path: 'model-artifact', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        allArtifacts: {
          label: 'All Artifacts',
          path: 'model-artifact',
        },
        editModelAssociation: {
          label: 'Edit Artifact',
          path: 'model-artifact/:uniqueId',
        },
        archivedArtifacts: {
          label: 'Archived Artifacts',
          path: 'model-artifact/archived',
        },
      },
    },
    DocumentInventory: {
      label: 'Documents',
      path: 'documents',
    },
    rules: {
      label: 'Rules',
      path: 'rules',
    },
    report: {
      label: 'Reports',
      path: 'reports',
    },
    bulkActions: {
      label: 'Bulk Actions',
      path: 'bulk-actions',
    },
    bulkUpload: {
      label: 'Bulk Upload',
      path: 'bulk-upload',
    },
    bulkTransition: {
      label: 'Bulk Transition',
      path: 'bulk-transition',
    },
    bulkUpdate: {
      label: 'Bulk Update',
      path: 'bulk-update',
    },
    bulkDocumentUpload: {
      label: 'Bulk Document Upload',
      path: 'bulk-document',
    },
    Configuration: {
      label: 'Configuration',
      path: 'configuration',
    },
    globalAttributes: {
      label: 'Attributes',
      path: 'attributes',
    },
    workflow: {
      label: 'Workflows',
      featureLabel: 'Workflow',
      path: 'workflows', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editWorkflow: {
          label: 'Edit Workflow',
          path: 'workflows/edit-workflow',
        },
      },
    },
    templateList: {
      label: 'Templates',
      path: 'templates', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        createTemplate: {
          label: 'Create Template',
          path: 'templates/create-template',
        },
        editTemplate: {
          label: 'Edit Template',
          path: 'templates/edit-template',
        },
      },
    },
    documentTemplate: {
      label: 'Document Template',
      path: 'document-template',
    },
    dynamicChart: {
      label: 'Dynamic Chart',
      path: 'dynamic-chart',
    },
    automation: {
      label: 'Automation',
      path: 'automation', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        createAutomation: {
          label: 'Create Automation',
          path: 'automation/create-automation',
        },
        updateAutomation: {
          label: 'Update Automation',
          path: 'automation/update-automation',
        },
      },
    },
    themeAndAppearance: {
      label: 'Theme and Appearance',
      path: 'theme-and-appearance',
    },
    emailTemplate: {
      label: 'Email Template',
      path: 'email-template',
    },
    helpAndSupport: {
      label: 'Help and Support',
      path: 'help',
    },
    administrativeOptions: {
      label: 'Administrative Options',
      path: 'administrative-options',
    },
    userManagement: {
      label: 'User Management',
      path: 'user-management',
    },
    appManagement: {
      label: 'App Management',
      path: 'app-management',
    },
    documentInventory: {
      label: 'Documents',
      path: 'documents',
    },
    accessRequest: {
      label: 'Access Request',
      featureLabel: accessRequestFeatureLabel, // If you are changing this change it in the template type as well
      path: 'accessRequest', // If you are changing this parents path, make sure to change it in subPaths as well
      subPaths: {
        editAccessRequest: {
          label: 'Edit Access Request',
          path: 'accessRequest/:uniqueId',
        },
      },
    },
  },
  LABELS: {
    modelInventory: {
      otherDetails: {
        label: 'Other Details',
      },
    },
  },
  // If you are changing this part, then make sure you are changing the paths and menus labels to these.
  TEMPLATE_TYPES: {
    ModelInventory: {
      label: 'ModelInventory',
      featureLabel: modelInventoryFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    ModelAssociation: {
      label: 'ModelAssociation',
      featureLabel: modelAssociationFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    ModelRequest: {
      label: 'Assessment',
      featureLabel: modelRequestFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    AccessRequest: {
      label: 'Access Request',
      featureLabel: accessRequestFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
    UseCase: {
      label: 'Use Case',
      featureLabel: useCaseFeatureLabel, // This feature label is used to show in the places where we want to show different name instead of we show in dropdown, for example our ModelInventory label is ModelInventory but we show it as Model everywhere.
      // If you are changing this change it in the Menus as well
    },
  },
};
