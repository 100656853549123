/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import axios from 'axios';
import SwalToast from '../components/Common/swalToast';
import { hostNeedsOrg } from './Utils';

const LOADER_HIDE_DELAY = 400;
const UNAUTHORIZED_RESPONSE_CODE = 401;

// eslint-disable-next-line no-unused-vars
const logout = () => {
  const keyToKeep = 'REALM_ID';
  const valueToKeep = localStorage.getItem(keyToKeep);
  localStorage.clear();
  sessionStorage.clear();
  if (valueToKeep !== null) {
    localStorage.setItem(keyToKeep, valueToKeep);
  }
  window.KEYCLOAK_CONFIG.logout();
};

/**
 * Get error message from AxiosError
 * @param {import("axios").AxiosError} error
 * @returns
 */
const getAxiosErrorMessage = (error) => {
  if (error?.response?.data?.msg) {
    return typeof error?.response?.data?.msg === 'string'
      ? error?.response?.data?.msg
      : JSON.stringify(error?.response?.data?.msg);
  }
  return error?.message;
};

const handleTokenRefreshSuccess = (newToken, error) => {
  localStorage.setItem('access_token', newToken);
  SwalToast({
    icon: 'success',
    title: 'Token refreshed successfully. Resending API requests.',
  });
  axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
  error.config.headers.Authorization = `Bearer ${newToken}`;
  return axios.request(error.config);
};

const handleTokenRefreshFailure = () => {
  SwalToast({
    icon: 'error',
    title: 'Failed to refresh the token.',
  });
  logout();
  return Promise.reject(new Error('Failed to refresh token'));
};

const handleUnauthorizedError = async (error) => {
  error.config._retry = true;

  if (!window.KEYCLOAK_CONFIG.isTokenExpired()) {
    logout();
    return Promise.reject(error instanceof Error ? error : new Error(error));
  }

  SwalToast({
    icon: 'info',
    title:
      'Please wait, your token might have expired. We are trying to refresh the token and resend the request.',
  });

  await window.KEYCLOAK_CONFIG.updateToken(1);
  const newToken = window.KEYCLOAK_CONFIG.token;

  if (newToken) {
    return handleTokenRefreshSuccess(newToken, error);
  }

  return handleTokenRefreshFailure();
};

export const initializeAxiosForKeycloak = () => {
  const showLoaderEvent = new Event('showLoader', { bubbles: true });
  const hideLoaderEvent = new Event('hideLoader', { bubbles: true });
  let numberOfApiCallsPending = 0;
  const headersCommonOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  window.addEventListener('offline', () => {
    window.offlineSnack = window.enqueueSnackbar(
      'You are working in offline mode.',
      {
        variant: 'info',
        preventDuplicate: true,
        persist: true,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        action: () => {},
      }
    );
  });

  window.addEventListener('online', () => {
    if (window.offlineSnack) {
      window.closeSnackbar(window.offlineSnack);
    }
  });

  axios.defaults.headers.common = headersCommonOptions;

  axios.interceptors.request.use((req) => {
    numberOfApiCallsPending += 1;
    if (req.loader !== false) {
      document.dispatchEvent(showLoaderEvent);
    }

    if (localStorage.getItem('access_token')) {
      req.headers.Authorization = `Bearer ${localStorage.getItem(
        'access_token'
      )}`;
    }

    if (hostNeedsOrg(req.url)) {
      req.headers.org =
        localStorage.getItem('REALM_ID') || `${window.CONFIG.ORG_NAME}`;
    }

    return req;
  });

  axios.interceptors.response.use(
    (response) => {
      numberOfApiCallsPending -= 1;
      if (numberOfApiCallsPending === 0) {
        setTimeout(
          () => document.dispatchEvent(hideLoaderEvent),
          LOADER_HIDE_DELAY
        );
      }
      return response;
    },
    async (error) => {
      numberOfApiCallsPending -= 1;

      const errorMessage = getAxiosErrorMessage(error);

      if (numberOfApiCallsPending === 0) {
        setTimeout(
          () => document.dispatchEvent(hideLoaderEvent),
          LOADER_HIDE_DELAY
        );
      }

      if (
        error.response?.status === UNAUTHORIZED_RESPONSE_CODE &&
        !error?.config?._retry
      ) {
        error.config._retry = true;

        try {
          return await handleUnauthorizedError(error);
        } catch (tokenRefreshError) {
          SwalToast({
            icon: 'error',
            title: 'Failed to refresh the token.',
          });
          logout();
          return Promise.reject(
            tokenRefreshError instanceof Error
              ? tokenRefreshError
              : new Error(tokenRefreshError)
          );
        }
      }

      // This condition for intercepting API errors for which we need to show yellow/info type toast message.
      if (error?.response?.headers?.['x-info'] === 'True') {
        SwalToast({
          icon: 'info',
          title: errorMessage,
        });
      }
      SwalToast({
        icon: 'error',
        title: errorMessage,
      });
      return Promise.reject(error instanceof Error ? error : new Error(error));
    }
  );
};

export default initializeAxiosForKeycloak;
