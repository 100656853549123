import { createContext, useContext } from 'react';

/**
 * @typedef SolyticsEditorContextValue
 * @property {import('react').MutableRefObject<import('@tiptap/react').Editor>} editorRef
 * @property {string[]} [hiddenToolbarItems = []]
 * @property {import('@tiptap/react').Extensions} [extraExtensions = []]
 * @property {boolean} [hideToolbar = false]
 */

/**
 * @type {import('react').Context<SolyticsEditorContextValue>}
 */
export const SolyticsEditorContext = createContext({
  editorRef: {
    current: null,
  },
  hiddenToolbarItems: [],
  extraExtensions: [],
  hideToolbar: false,
});

export const useSolyticsEditorContext = () => useContext(SolyticsEditorContext);
export const SolyticsEditorProvider = SolyticsEditorContext.Provider;
