import { useState } from 'react';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useThemeAppearance() {
  const defaultTheme = useTheme();
  const defaultLocalStorageTheme = JSON.parse(
    localStorage.getItem('theme') || '{}'
  );

  const saveThemeInLocalStorage = (theme) => {
    localStorage.setItem('theme', JSON.stringify(theme));
  };

  const [currentSavedTheme, setCurrentSavedTheme] = useState(
    defaultLocalStorageTheme || {}
  );
  const getCurrentTheme = () => {
    return axios
      .get(`${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.TA.THEME}`)
      .then((res) => {
        setCurrentSavedTheme(res.data);
        saveThemeInLocalStorage(res.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const saveTheme = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.TA.THEME}`,
        body
      )
      .then((res) => {
        saveThemeInLocalStorage(body);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    getCurrentTheme,
    currentSavedTheme,
    saveTheme,
    defaultTheme,
    setCurrentSavedTheme,
  };
}
