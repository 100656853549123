import { getLabelPathFromLabelConfig } from '../utils/Utils';
import { DEFAULT_ROUTE_CONFIG } from './defaultRoutesConfig';

const MODULE_SUB_MODULE = {
  MI: {
    objKey: 'MI',
    label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.modelInventory.keyPath, 'label', 'Models')}`,
    key: 'ModelInventory',
    subModules: {
      MI: {
        objKey: 'MI',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.modelInventory.keyPath, 'featureLabel', 'My Models')}`,
        key: 'modelInventory',
      },
      CM: {
        objKey: 'CM',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.createModel.keyPath, 'label', 'Add Model')}`,
        key: 'createModel',
      },
      DB: {
        objKey: 'DB',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.dashboard.keyPath, 'label', 'Dashboard')}`,
        key: 'dashboard',
      },
      DBC: {
        objKey: 'DBC',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.report.keyPath, 'label', 'Reports')}`,
        key: 'dashboardReports',
      },
      DBN: {
        objKey: 'DBN',
        label: 'Model Architecture',
        key: 'dashboardNetwork',
      },
      MR: {
        objKey: 'MR',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.modelRequest.keyPath, 'label', 'Assessment')}`,
        key: 'modelRequest',
      },
      UC: {
        objKey: 'UC',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.useCase.keyPath, 'label', 'Use Case')}`,
        key: 'useCase',
      },
    },
  },
  MA: {
    objKey: 'MA',
    label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.ModelAssociation.keyPath, 'label', 'Model Artifacts')}`,
    key: 'ModelAssociation',
    subModules: {},
  },
  DI: {
    objKey: 'DI',
    label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.documentInventory.keyPath, 'label', 'Documents')}`,
    key: 'DocumentInventory',
    subModules: {
      DI: {
        objKey: 'DI',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.documentInventory.keyPath, 'label', 'Documents')}`,
        key: 'documentInventory',
      },
    },
  },
  RR: {
    objKey: 'RR',
    label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.rules.keyPath, 'label', 'Rules')} & ${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.report.keyPath, 'label', 'Reports')}`,
    key: 'RulesReports',
    subModules: {
      RU: {
        objKey: 'RU',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.rules.keyPath, 'label', 'Rules')}`,
        key: 'rules',
      },
      RP: {
        objKey: 'RP',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.report.keyPath, 'label', 'Reports')}`,
        key: 'report',
      },
    },
  },
  BA: {
    objKey: 'BA',
    label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.bulkActions.keyPath, 'label', 'Bulk Actions')}`,
    key: 'BulkActions',
    subModules: {
      BU: {
        objKey: 'BU',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.bulkUpload.keyPath, 'label', 'Bulk upload')}`,
        key: 'bulkUpload',
      },
      BT: {
        objKey: 'BT',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.bulkTransition.keyPath, 'label', 'Bulk transition')}`,
        key: 'bulkTransition',
      },
      BUpdate: {
        objKey: 'BUpdate',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.bulkUpdate.keyPath, 'label', 'Bulk update')}`,
        key: 'bulkUpdate',
      },
    },
  },
  CNF: {
    objKey: 'CNF',
    label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.configuration.keyPath, 'label', 'Configuration')}`,
    key: 'Configuration',
    subModules: {
      WF: {
        objKey: 'WF',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.workflow.keyPath, 'label', 'Workflows')}`,
        key: 'workflow',
      },
      TL: {
        objKey: 'TL',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.templateList.keyPath, 'label', 'Templates')}`,
        key: 'templateList',
      },
      GA: {
        objKey: 'GA',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.globalAttributes.keyPath, 'label', 'Attributes')}`,
        key: 'globalAttributes',
      },
      DT: {
        objKey: 'DT',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.documentTemplate.keyPath, 'label', 'Document Template')}`,
        key: 'documentTemplate',
      },
      DC: {
        objKey: 'DC',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.dynamicChart.keyPath, 'label', 'Dynamic Chart')}`,
        key: 'dynamicChart',
      },
      TA: {
        objKey: 'TA',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.themeAndAppearance.keyPath, 'label', 'Theme and Appearance')}`,
        key: 'themeAndAppearance',
      },
      AUT: {
        objKey: 'AUT',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.automation.keyPath, 'label', 'Automation')}`,
        key: 'automation',
      },
      ET: {
        objKey: 'ET',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.emailTemplate.keyPath, 'label', 'Email Template')}`,
        key: 'emailTemplate',
      },
    },
  },
  AO: {
    objKey: 'AO',
    label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.administrativeOptions.keyPath, 'label', 'Administrative Options')}`,
    key: 'AdministrativeOptions',
    subModules: {
      UM: {
        objKey: 'UM',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.userManagement.keyPath, 'label', 'User management')}`,
        key: 'userManagement',
      },
      AM: {
        objKey: 'AM',
        label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.appManagement.keyPath, 'label', 'App management')}`,
        key: 'appManagement',
      },
    },
  },
  AR: {
    objKey: 'AR',
    label: `${getLabelPathFromLabelConfig(DEFAULT_ROUTE_CONFIG.accessRequest.keyPath, 'label', 'Access Request')}`,
    key: 'accessRequest',
    subModules: {},
  },
};

const DYNAMIC_MODULE_SUBMODULE = {
  MI: {
    objKey: 'MI',
    label: `${getLabelPathFromLabelConfig('TEMPLATE_TYPES.ModelInventory', 'label', 'Models')}`,
    key: 'ModelInventory',
    subModules: {},
  },
  MA: {
    objKey: 'MA',
    label: `${getLabelPathFromLabelConfig('TEMPLATE_TYPES.ModelAssociation', 'label', 'Model Artifacts')}`,
    key: 'ModelAssociation',
    subModules: {},
  },
};

export default MODULE_SUB_MODULE;

export { DYNAMIC_MODULE_SUBMODULE };
