// @ts-nocheck
import { useCallback, useMemo } from 'react';

/**
 * @typedef {'heading' | 'paragraph' | 'bold' | 'italic' | 'strike' | 'highlight' | 'code' | 'bulletList' | 'orderedList' | 'codeBlock' | 'blockquote' | 'underline'} ToggleAction
 */

/**
 * @typedef {Record<ToggleAction, () => import('@tiptap/react').ChainedCommands>} ToggleActionRecord
 */

/**
 * @typedef ToggleHeadingAttributes
 * @property {1 | 2 | 3 | 4 | 5 | 6} level Level of heading
 */

/**
 * @typedef ToggleHighlightAttributes
 * @property {string} [color] Color of highlight
 */

/**
 * @typedef { ToggleHeadingAttributes | ToggleHighlightAttributes } ToggleActionAttributes
 */

/**
 * @typedef InsertTableOptions
 * @property {number} [rows]
 * @property {number} [cols]
 * @property {boolean} [withHeaderRow]
 */

/**
 *
 * @param {import('@tiptap/react').Editor} editor
 * @param {ToggleAction} action
 * @param {ToggleActionAttributes} [attributes]
 * @returns {() => import('@tiptap/react').ChainedCommands}
 */
const getToggleChain = (editor, action, attributes = {}) => {
  /**
   * @type {ToggleActionRecord}
   */
  const toggleAction = {
    heading: () => editor.chain().focus().toggleHeading(attributes),
    paragraph: () => editor.chain().focus().setParagraph(),
    bold: () => editor.chain().focus().toggleBold(),
    highlight: () => editor.chain().focus().toggleHighlight(attributes),
    italic: () => editor.chain().focus().toggleItalic(),
    strike: () => editor.chain().focus().toggleStrike(),
    code: () => editor.chain().focus().toggleCode(),
    bulletList: () => editor.chain().focus().toggleBulletList(),
    orderedList: () => editor.chain().focus().toggleOrderedList(),
    codeBlock: () => editor.chain().focus().toggleCodeBlock(),
    blockquote: () => editor.chain().focus().toggleBlockquote(),
    underline: () => editor.chain().focus().toggleUnderline(),
  };
  return toggleAction[action];
};

/**
 *
 * @param {import('@tiptap/react').Editor} editor
 * @returns
 */
export const getTableOptions = (editor) => [
  {
    type: 'tableOptions',
    options: [
      {
        id: 'tableOptions:toggleHeaderRow',
        onClick: () => editor.chain().focus().toggleHeaderRow().run(),
      },
    ],
  },
];

export const TEXT_STYLE_OPTIONS = [
  {
    label: 'Normal Text',
    value: 0,
  },
  {
    label: 'Heading 1',
    value: 1,
  },
  {
    label: 'Heading 2',
    value: 2,
  },
  {
    label: 'Heading 3',
    value: 3,
  },
  {
    label: 'Heading 4',
    value: 4,
  },
  {
    label: 'Heading 5',
    value: 5,
  },
  {
    label: 'Heading 6',
    value: 6,
  },
];

export const FONT_SIZES = [
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
  '26',
  '28',
  '36',
  '48',
  '72',
];

/**
 * @param {import('@tiptap/react').Editor} editor
 */
const useSolyticsEditorToolbar = (editor) => {
  /**
   * @type {(action: ToggleAction, attributes?: ToggleActionAttributes) => boolean}
   */
  const handleToggle = useCallback(
    (action, attributes = {}) => {
      const chain = getToggleChain(editor, action, attributes);
      return chain().run();
    },
    [editor]
  );

  /**
   * @type {(alignment: 'left' | 'center' | 'right') => boolean}
   */
  const handleTextAlign = useCallback(
    (alignment) => editor.chain().focus().setTextAlign(alignment).run(),
    [editor]
  );

  /**
   * @type {() => boolean}
   */
  const insertDivider = useCallback(
    () => editor.chain().focus().setHorizontalRule().run(),
    [editor]
  );

  const handleTextColor = useCallback(
    (color) => editor.chain().focus().setColor(color),
    [editor]
  );

  const activeTextColor = useMemo(
    () => editor.getAttributes('textStyle').color,
    [editor]
  );

  const undo = useCallback(() => editor.chain().focus().undo().run(), [editor]);
  const redo = useCallback(() => editor.chain().focus().redo().run(), [editor]);
  const canUndo = editor.can().chain().focus().undo().run();
  const canRedo = editor.can().chain().focus().redo().run();

  const getActiveTextStyle = () => {
    if (editor.isActive('paragraph')) {
      return 0;
    }
    const headingLevels = TEXT_STYLE_OPTIONS.slice(1).map((style) => ({
      level: style.value,
      isActive: editor.isActive('heading', { level: style.value }),
    }));
    const activeHeading = headingLevels.find((heading) => heading.isActive);
    if (activeHeading) {
      return activeHeading.level;
    }
    return 0;
  };

  const getCurrentFont = () => {
    const { fontSize } = editor.getAttributes('textStyle');
    return fontSize || '16';
  };

  const activeTextStyle = useMemo(() => {
    if (editor.isActive('paragraph')) {
      return 0;
    }
    const headingLevels = TEXT_STYLE_OPTIONS.slice(1).map((style) => ({
      level: style.value,
      isActive: editor.isActive('heading', { level: style.value }),
    }));
    const activeHeading = headingLevels.find((heading) => heading.isActive);
    if (activeHeading) {
      return activeHeading.level;
    }
    return 0;
  }, [editor]);

  const addImage = (base64) => {
    editor
      .chain()
      .insertContent({
        type: 'resizable-image',
        attrs: {
          imageURL: base64,
        },
      })
      .run();
  };

  /**
   * @param {InsertTableOptions} insertTableOptions
   * @returns {boolean}
   */
  const insertTable = (insertTableOptions) =>
    editor.chain().focus().insertTable(insertTableOptions).run();
  const addColumnBefore = () => editor.chain().focus().addColumnBefore().run();
  const addColumnAfter = () => editor.chain().focus().addColumnAfter().run();
  const addRowBefore = () => editor.chain().focus().addRowBefore().run();
  const addRowAfter = () => editor.chain().focus().addRowAfter().run();
  const deleteColumn = () => editor.chain().focus().deleteColumn().run();
  const deleteRow = () => editor.chain().focus().deleteRow().run();
  const deleteTable = () => editor.chain().focus().deleteTable().run();
  const mergeCells = () => editor.chain().focus().mergeCells().run();
  const splitCell = () => editor.chain().focus().splitCell().run();
  const toggleHeaderColumn = () =>
    editor.chain().focus().toggleHeaderColumn.run();
  const toggleHeaderRow = () => editor.chain().focus().toggleHeaderRow.run();
  const fixTables = () => editor.chain().focus().fixTables().run();

  const tableUtils = {
    insertTable,
    addColumnAfter,
    addColumnBefore,
    addRowAfter,
    addRowBefore,
    deleteColumn,
    deleteRow,
    deleteTable,
    mergeCells,
    splitCell,
    toggleHeaderColumn,
    toggleHeaderRow,
    fixTables,
  };

  return {
    handleToggle,
    handleTextAlign,
    insertDivider,
    handleTextColor,
    activeTextColor,
    undo,
    redo,
    canUndo,
    canRedo,
    getActiveTextStyle,
    getCurrentFont,
    addImage,
    insertTable,
    addColumnAfter,
    addColumnBefore,
    addRowAfter,
    addRowBefore,
    deleteColumn,
    deleteRow,
    deleteTable,
    mergeCells,
    splitCell,
    toggleHeaderColumn,
    toggleHeaderRow,
    fixTables,
    tableUtils,
    activeTextStyle,
  };
};

export default useSolyticsEditorToolbar;
