/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
  Stack,
  Button,
  TextField,
  Autocomplete,
  Switch,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import DOMPurify from 'dompurify';

import { addMethod, object, string } from 'yup';
import { DropDown, DropdownItem } from '../../Common/DropDown';
import { ENTITY_TYPES } from '../../../const/CommonConst';
import DeleteModal from '../../Common/Modals/DeleteModal';
import {
  createSuggestionsFromTagResponse,
  getErrorHelperText,
  getLabelPathFromLabelConfig,
  isValueEmpty,
} from '../../../utils/Utils';
import swalToast from '../../Common/swalToast';
import DialogTransition from '../../Common/Transition/DialogTransition';
import { ReactComponent as DropdownArrow } from '../../../assets/images/DropdownArrow.svg';
import EmailTemplateEditor from './EmailTemplateEditor';

const handleGetOptionLabel = (option) => option;
const handleIsOptionEqualToValue = (option, value) => option === value;

const DEFINE_EVENTS = [
  'Team Members Added',
  'Entity Transitions',
  'Assigned to',
  'Mentioned in Chat',
  'Event Reminder',
  'Event Created',
  'Event Completed',
];

function isJsonString(str) {
  if (isEmpty(str)) {
    return true;
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const PLACEHOLDER = 'Write body of email here...';

const CreateEditEmailTemplate = ({
  open,
  handleClose,
  mode,
  entityList = [],
  createEmailTemplate,
  activeTemplate,
  updateEmailTemplate,
  fetchEmailTemplate,
  getEmailTemplateTags,
}) => {
  const [step, setStep] = useState('first');
  const [html, setHtml] = useState('');
  const [templateId, setTemplateId] = useState(null);
  const [templateTags, setTemplateTags] = useState([]);
  const [subject, setSubject] = useState('');
  useMemo(() => {
    setTemplateId(activeTemplate?.email_template_id);
  }, [activeTemplate]);

  const formValidationSchema = object({
    event_type: string().required('Required'),
    event_name: string().required('Required'),
    entity_type: string().required('Required'),
    email_template_description: string().required('Required'),
    recipient_email: string().test(
      'valid-emails',
      'Invalid email format',
      (value) => {
        if (!value) {
          return true;
        } // Skip if empty (required validation will handle it)
        const emails = value.split(',').map((email) => email.trim());
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
        return emails.every((email) => emailRegex.test(email));
      }
    ),
    template_variable: string().test(
      'isJsonString',
      'Enter valid json',
      isJsonString
    ),
  });
  const getRequestType = useCallback(
    (_activeTemplate) => {
      if (_activeTemplate?.request_type === 'ModelRequest') {
        return 'ModelRequest';
      }
      if (_activeTemplate?.request_type === 'AccessRequest') {
        return 'AccessRequest';
      }
      if (_activeTemplate?.request_type === 'UseCase') {
        return 'UseCase';
      }
      return _activeTemplate?.entity_type;
    },
    [activeTemplate]
  );

  const initialValues = useMemo(() => {
    if (mode === 'Create') {
      return {
        event_type: '',
        event_name: '',
        subject: '',
        body: '',
        entity_name: '',
        entity_type: '',
        request_type: 'Normal',
        email_template_description: '',
        recipient_email: '',
        relatedEntity: { label: '', value: '' },
        template_variable: `{}`,
        is_no_code_open: true,
      };
    }

    return {
      event_type: activeTemplate?.event_type,
      event_name: activeTemplate?.event_name,
      subject: activeTemplate?.subject,
      body: activeTemplate?.body || '',
      entity_name: activeTemplate?.entity_name,
      entity_type: getRequestType(activeTemplate),
      request_type: activeTemplate?.request_type,
      email_template_description: activeTemplate?.email_template_description,
      recipient_email: activeTemplate?.recipient_email?.join(',') || '',
      relatedEntity: {
        label: activeTemplate?.entity_name || '',
        value: activeTemplate?.entity_name || '',
        type: activeTemplate?.entity_type || '',
      },
      template_variable:
        JSON.stringify(activeTemplate?.template_variable) || `{}`,
      is_no_code_open: activeTemplate?.is_no_code_open,
    };
  }, [mode, activeTemplate]);
  useMemo(() => {
    setHtml(activeTemplate?.body || '');
  }, [activeTemplate]);
  useMemo(() => {
    setSubject(activeTemplate?.subject || '');
  }, [activeTemplate]);

  const fetchEmailTemplateTags = useCallback(
    (params) => {
      getEmailTemplateTags(params).then((res) => {
        const tags = res.data?.EntityTags || {};
        setTemplateTags(createSuggestionsFromTagResponse(tags));
      });
    },
    [setTemplateTags, getEmailTemplateTags]
  );

  addMethod(string, 'isJsonString', isJsonString);

  const formikForm = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      const payload = {
        ...values,
        template_variable: JSON.parse(values.template_variable),
        entity_type:
          values?.request_type !== 'Normal'
            ? 'ModelInventory'
            : values?.entity_type,
        entity_name: values?.relatedEntity?.label || null,
        recipient_email: isValueEmpty(values?.recipient_email)
          ? []
          : values.recipient_email.split(','),
      };
      delete payload.relatedEntity;

      if (mode === 'Create') {
        createEmailTemplate(payload).then((res) => {
          if (res) {
            setTemplateId(res?.data?.template_id);
            swalToast({
              title: res?.data?.msg,
              icon: 'success',
            });

            fetchEmailTemplate();
            fetchEmailTemplateTags({
              template_id: res?.data?.template_id,
            });
          }
          setStep('second');
        });
      } else {
        // eslint-disable-next-line no-use-before-define
        updateTemplate(payload, {
          template_id: templateId,
        });
      }
    },
  });

  /**
   * Resets the Formik form and closes the modal.
   *
   * @function handleModalClose
   * @returns {void}
   */
  const handleModalClose = useCallback(() => {
    formikForm.handleReset();
    handleClose();
  }, [formikForm, handleClose]);

  const updateTemplate = useCallback(
    (body, params, _step = 'first') => {
      updateEmailTemplate(body, params).then((res) => {
        if (res) {
          swalToast({
            icon: 'success',
            title: res?.data?.msg,
          });

          if (_step === 'first') {
            fetchEmailTemplateTags({
              template_id: res?.data?.template_id,
            });
            setStep('second');
          } else {
            handleModalClose();
          }

          fetchEmailTemplate();
        }
      });
    },
    [updateEmailTemplate, step, fetchEmailTemplate, handleModalClose]
  );

  const handleResetForm = useCallback(() => {
    formikForm.handleReset();
  }, [formikForm]);

  // Handle option change
  const handleDataChange = useCallback(
    (_event, newValue) => {
      formikForm.setFieldValue('event_type', newValue);
      formikForm.setFieldValue('event_name', '');
    },
    [formikForm.setFieldValue]
  );
  const handleRenderOption = useCallback(
    (props, option) => (
      <Box {...props}>
        <Typography
          variant="body2"
          noWrap
          title={option.label}
          textOverflow="ellipsis"
        >
          {option ? `${option}` : ''}
        </Typography>
      </Box>
    ),
    []
  );
  // Render input field
  const handleRenderEventTypeInput = useCallback(
    (params) => (
      <TextField
        {...params}
        required
        label="Event Type"
        variant="outlined"
        placeholder="Select an event type"
        helperText={getErrorHelperText(
          formikForm?.errors?.event_type,
          formikForm?.touched?.event_type
        )}
        error={Boolean(
          getErrorHelperText(
            formikForm?.errors?.event_type,
            formikForm?.touched?.event_type
          )
        )}
      />
    ),
    [formikForm]
  );
  const handleRenderEventInput = useCallback(
    (params) => (
      <TextField
        {...params}
        label="Event Name"
        variant="outlined"
        placeholder="Select an event name"
        helperText={getErrorHelperText(
          formikForm?.errors?.event_name,
          formikForm?.touched?.event_name
        )}
        error={Boolean(
          getErrorHelperText(
            formikForm?.errors?.event_name,
            formikForm?.touched?.event_name
          )
        )}
      />
    ),
    [formikForm]
  );
  const handleEventNameChange = useCallback(
    (_event, newValue) => {
      formikForm.setFieldValue('event_name', newValue);
    },
    [formikForm]
  );
  const handleGetTemplateOptionLabel = useCallback((option) => {
    return option?.label;
  }, []);
  const handleIsTemplateOptionEqualToValue = useCallback((option, value) => {
    return option?.value === value?.value;
  }, []);
  const renderTemplateInput = useCallback(
    (params) => {
      return (
        <TextField
          {...params}
          label="Template name"
          placeholder="Select template."
          helperText={
            formikForm?.errors?.entityName?.label &&
            formikForm?.touched?.entityName
              ? formikForm?.errors?.entityName?.label
              : null
          }
          error={
            Boolean(formikForm?.errors?.entityName?.label) &&
            formikForm?.touched?.entityName
          }
        />
      );
    },
    [formikForm]
  );
  const renderTemplateOption = useCallback((props, option) => {
    return (
      <Box {...props}>
        <Typography
          variant="body2"
          noWrap
          title={option.label}
          sx={{
            wordBreak: 'break-word',
            whiteSpace: 'wrap',
          }}
        >
          {option?.label ? `${option?.label}` : ''}
        </Typography>
      </Box>
    );
  }, []);

  const handleEntityNameOnChange = useCallback(
    (_event, newValue) => {
      formikForm.setFieldValue(
        'relatedEntity',
        newValue || { label: '', value: '' }
      );
    },
    [formikForm]
  );

  const handleSave = useCallback(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const bodyContent = doc.body.innerHTML.trim();
    const payload = {
      ...formikForm.values,
      template_variable: JSON.parse(formikForm.values.template_variable),
      entity_name: formikForm?.values?.relatedEntity?.label || null,
      recipient_email: isValueEmpty(formikForm?.values?.recipient_email)
        ? []
        : formikForm?.values.recipient_email.split(','),
      entity_type:
        formikForm?.values?.request_type !== 'Normal'
          ? 'ModelInventory'
          : formikForm?.values?.entity_type,
      body: DOMPurify.sanitize(bodyContent),
      subject: DOMPurify.sanitize(subject),
    };
    delete payload.relatedEntity;
    updateTemplate(
      payload,
      {
        template_id: templateId,
      },
      step
    );
  }, [html, templateId, step, subject]);

  const templateOptions = useMemo(() => {
    return entityList
      ?.filter((_template) => {
        if (
          formikForm?.values?.entity_type === _template?.entity_type &&
          _template?.request_type === 'Normal'
        ) {
          return true;
        }
        if (
          !['ModelInventory', 'ModelAssociation'].includes(
            formikForm?.values?.entity_type
          )
        ) {
          return _template?.request_type === formikForm?.values?.request_type;
        }

        return false;
      })
      ?.map((template) => {
        return {
          label: template?.entity_name,
          value: template?.entity_name,
        };
      });
  }, [
    entityList,
    formikForm.values?.entity_type,
    formikForm?.values?.request_type,
  ]);

  const [actionType, setActionType] = useState(false);
  const handleChangeIsNodeCodeTool = useCallback(() => {
    setActionType(true);
  }, [formikForm]);

  const handleEmailBodyChange = useCallback(
    (e) => {
      setHtml(e.target.value);
    },
    [setHtml]
  );

  const closeSwitchModal = useCallback(() => {
    setActionType(false);
  }, [setActionType]);

  const closeConfirm = useCallback(() => {
    setHtml('');
    if (!formikForm?.values?.is_no_code_open) {
      fetchEmailTemplateTags({
        template_id: templateId,
      });
    }

    formikForm.setFieldValue(
      'is_no_code_open',
      !formikForm?.values?.is_no_code_open
    );
    closeSwitchModal();
  }, [formikForm, closeSwitchModal, setHtml, templateId]);

  const handleChangeTemplateType = useCallback(
    (event) => {
      if (event?.target?.value === 'ModelRequest') {
        formikForm.setFieldValue('request_type', 'ModelRequest');
      } else if (event?.target?.value === 'AccessRequest') {
        formikForm.setFieldValue('request_type', 'AccessRequest');
      } else if (event?.target?.value === 'UseCase') {
        formikForm.setFieldValue('request_type', 'UseCase');
      } else {
        formikForm.setFieldValue('request_type', 'Normal');
      }
      formikForm.setFieldValue('entity_type', event?.target?.value);
      formikForm.setFieldValue('relatedEntity', { label: '', value: '' });
    },
    [formikForm]
  );

  return (
    <Box>
      <Dialog
        maxWidth={step === 'first' ? 'sm' : 'lg'}
        open={open}
        TransitionComponent={DialogTransition}
      >
        <Grid container>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={11}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h3">{`${mode} Email Template`}</Typography>
              </Box>
            </Grid>

            <Grid item xs={1} display="flex" justifyContent="flex-end">
              <IconButton onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid
              item
              mt={2}
              mr={4}
              xs={12}
              display="flex"
              justifyContent="flex-end"
            >
              {step !== 'first' && (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    Enable HTML
                  </Typography>
                  <Box>
                    <Typography>No</Typography>
                    <Switch
                      name="is_no_code_open"
                      checked={!formikForm?.values?.is_no_code_open === true}
                      onChange={handleChangeIsNodeCodeTool}
                    />
                    <Typography>Yes</Typography>
                  </Box>
                </Stack>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              display={step === 'first' ? 'flex' : 'none'}
            >
              <Grid item xs={6}>
                <Autocomplete
                  required
                  id="event_type"
                  disabled={mode === 'Edit'}
                  options={['Defined Events', 'Custom Events']}
                  value={formikForm?.values?.event_type}
                  getOptionLabel={handleGetOptionLabel}
                  isOptionEqualToValue={handleIsOptionEqualToValue}
                  onChange={handleDataChange}
                  noOptionsText="No event type available."
                  popupIcon={<DropdownArrow />}
                  renderOption={handleRenderOption}
                  renderInput={handleRenderEventTypeInput}
                />
              </Grid>
              <Grid item xs={6}>
                {formikForm?.values?.event_type === 'Defined Events' ? (
                  <Autocomplete
                    id="event_name"
                    disabled={mode === 'Edit'}
                    options={DEFINE_EVENTS}
                    value={formikForm?.values?.event_name}
                    getOptionLabel={handleGetOptionLabel}
                    isOptionEqualToValue={handleIsOptionEqualToValue}
                    onChange={handleEventNameChange}
                    noOptionsText="No event present"
                    popupIcon={<DropdownArrow />}
                    renderOption={handleRenderOption}
                    renderInput={handleRenderEventInput}
                  />
                ) : (
                  <TextField
                    required
                    name="event_name"
                    disabled={mode === 'Edit'}
                    value={formikForm?.values?.event_name}
                    {...formikForm.getFieldProps('event_name')}
                    placeholder="Enter Event Name."
                    label="Event Name"
                    helperText={getErrorHelperText(
                      formikForm?.errors?.event_name,
                      formikForm?.touched?.event_name
                    )}
                    error={Boolean(
                      getErrorHelperText(
                        formikForm?.errors?.event_name,
                        formikForm?.touched?.event_name
                      )
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                <DropDown
                  required
                  label="Template Type"
                  value={formikForm?.values?.entity_type}
                  onChange={handleChangeTemplateType}
                  disabled={mode === 'Edit'}
                  helperText={getErrorHelperText(
                    formikForm?.errors?.entity_type,
                    formikForm?.touched?.entity_type
                  )}
                  error={Boolean(
                    getErrorHelperText(
                      formikForm?.errors?.entity_type,
                      formikForm?.touched?.entity_type
                    )
                  )}
                >
                  {[
                    ...ENTITY_TYPES,
                    {
                      label: 'Assessment',
                      value: 'ModelRequest',
                    },
                    {
                      label: 'Access Request',
                      value: 'AccessRequest',
                    },
                    { label: 'UseCase', value: 'UseCase' },
                  ].map((entityType) => (
                    <DropdownItem
                      key={entityType?.id}
                      value={entityType?.value}
                    >
                      {getLabelPathFromLabelConfig(
                        `TEMPLATE_TYPES.${entityType?.value}`,
                        'label',
                        entityType?.label
                      )}
                    </DropdownItem>
                  ))}
                </DropDown>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="template_name"
                  options={
                    isValueEmpty(formikForm?.values?.entity_type)
                      ? []
                      : templateOptions
                  }
                  value={formikForm?.values?.relatedEntity}
                  getOptionLabel={handleGetTemplateOptionLabel}
                  isOptionEqualToValue={handleIsTemplateOptionEqualToValue}
                  onChange={handleEntityNameOnChange}
                  noOptionsText={
                    isValueEmpty(formikForm?.values?.entity_type)
                      ? 'Select an template type first.'
                      : 'No template available.'
                  }
                  popupIcon={<DropdownArrow />}
                  renderOption={renderTemplateOption}
                  renderInput={renderTemplateInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  name="description"
                  value={formikForm?.values?.email_template_description}
                  {...formikForm.getFieldProps('email_template_description')}
                  placeholder="Enter description."
                  label="Description"
                  helperText={getErrorHelperText(
                    formikForm?.errors?.email_template_description,
                    formikForm?.touched?.email_template_description
                  )}
                  error={Boolean(
                    getErrorHelperText(
                      formikForm?.errors?.email_template_description,
                      formikForm?.touched?.email_template_description
                    )
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                {' '}
                <TextField
                  name="recipient_email"
                  value={formikForm?.values?.recipient_email}
                  {...formikForm.getFieldProps('recipient_email')}
                  placeholder="Enter email by comma separated."
                  label="Recipient Email"
                  helperText={
                    formikForm?.errors?.recipient_email &&
                    formikForm?.touched?.recipient_email
                      ? formikForm.errors.recipient_email
                      : null
                  }
                  error={
                    Boolean(formikForm?.errors?.recipient_email) &&
                    formikForm?.touched?.recipient_email
                  }
                />
              </Grid>

              {formikForm?.values?.event_type === 'Custom Events' && (
                <Grid item xs={12}>
                  <TextField
                    name="template_variable"
                    multiline
                    rows={4}
                    value={formikForm?.values?.template_variable}
                    {...formikForm.getFieldProps('template_variable')}
                    placeholder="Enter template variable as a json."
                    label="Template Variable"
                    helperText={
                      formikForm?.errors?.template_variable &&
                      formikForm?.touched?.template_variable
                        ? formikForm.errors.template_variable
                        : null
                    }
                    error={
                      Boolean(formikForm?.errors?.template_variable) &&
                      formikForm?.touched?.template_variable
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              display={step === 'first' ? 'none' : 'flex'}
              mt="2px"
            >
              <Box
                sx={{
                  width: '96%',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  mt: 1,
                  mb: 1,
                  '&:focus-within': {
                    borderColor: '#1976d2',
                  },
                }}
              >
                <EmailTemplateEditor
                  value={subject}
                  onChange={setSubject}
                  templateTags={templateTags || []}
                  placeholder="Enter subject."
                  hideToolbar={true}
                  padding="8px 0px"
                />
              </Box>
              {formikForm?.values?.is_no_code_open ? (
                <EmailTemplateEditor
                  onChange={setHtml}
                  value={html}
                  padding="0px 0px"
                  templateTags={templateTags || []}
                  placeholder={PLACEHOLDER}
                />
              ) : (
                <TextField
                  name="body of email"
                  multiline
                  rows={4}
                  value={html}
                  sx={{
                    '& .MuiInputBase-root': {
                      overflow: 'auto',
                    },
                    '& .MuiInputBase-input': {
                      resize: 'both', // Enable resizing
                    },
                  }}
                  onChange={handleEmailBodyChange}
                  placeholder={PLACEHOLDER}
                  label="body of email"
                />
              )}
            </Grid>
            <Grid>
              <Stack direction="row" spacing={3} justifyContent="center" mt={3}>
                {step === 'first' && (
                  <Button onClick={handleResetForm}>RESET</Button>
                )}
                {step === 'first' ? (
                  <Button onClick={formikForm.handleSubmit} variant="contained">
                    NEXT
                  </Button>
                ) : (
                  <Button onClick={handleSave} variant="contained">
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>

            <DeleteModal
              open={actionType}
              handleClose={closeSwitchModal}
              deleteConfirm={closeConfirm}
              deleteConfirmText="Confirm"
              alertLabelText="Are you sure you want to switch ?"
              warningText="Your data will be lost."
            />
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

CreateEditEmailTemplate.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  entityList: PropTypes.arrayOf([PropTypes.object]).isRequired,
  createEmailTemplate: PropTypes.func.isRequired,
  activeTemplate: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateEmailTemplate: PropTypes.func.isRequired,
  fetchEmailTemplate: PropTypes.func.isRequired,
  getEmailTemplateTags: PropTypes.func.isRequired,
};

export default CreateEditEmailTemplate;
