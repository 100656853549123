import { Info } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const HealthColumnHeader = ({ column }) => {
  return (
    <Box
      whiteSpace="noWrap"
      overflow="hidden"
      style={{ cursor: 'pointer' }}
      marginRight={1}
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={1}
    >
      <Box component="div" title={column?.heading}>
        {column?.heading}
      </Box>
      <Tooltip title="Click on the circle to view alerts." placement="top">
        <Info sx={{ fontSize: 14 }} />
      </Tooltip>
    </Box>
  );
};
HealthColumnHeader.propTypes = {
  column: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default HealthColumnHeader;
