// Values for these properties will get automatically filled dynamically in getTagsFromRoute utils function where it is used. Because we have managed everything using the keys and labelconfig files
export const PATH_TAGS = {
  dashboard: {
    path: '',
    tags: {
      dashboard: '',
    },
  },
  inventory: {
    path: '',
    tags: {
      inventory: '',
    },
  },
  modelInventory: {
    path: '',
    tags: {
      models: '',
    },
  },
  createModel: {
    path: '',
    tags: {
      models: '',
      createModel: '',
    },
  },
  editModel: {
    path: '',
    tags: {
      models: '',
      editModel: '',
    },
  },
  archivedModels: {
    path: '',
    tags: {
      models: '',
      archivedModels: '',
    },
  },
  modelRequest: {
    path: '',
    tags: {
      modelRequest: '',
    },
  },
  editModelRequest: {
    path: '',
    tags: {
      modelRequest: '',
      editModelRequest: '',
    },
  },
  useCase: {
    path: '',
    tags: {
      useCase: '',
    },
  },
  editUseCase: {
    path: '',
    tags: {
      useCase: '',
      editUseCase: '',
    },
  },
  ModelAssociation: {
    path: 'artifacts',
    tags: {
      ModelAssociation: '',
    },
  },
  allArtifacts: {
    path: '',
    tags: {
      ModelAssociation: '',
      allArtifacts: '',
    },
  },
  editModelAssociation: {
    path: '',
    tags: {
      ModelAssociation: '',
      editModelAssociation: '',
    },
  },
  archivedArtifacts: {
    path: '',
    tags: {
      ModelAssociation: '',
      archivedArtifacts: '',
    },
  },
  DocumentInventory: {
    path: '',
    tags: {
      DocumentInventory: '',
    },
  },
  rules: {
    path: '',
    tags: {
      rules: '',
    },
  },
  report: {
    path: '',
    tags: {
      report: '',
    },
  },
  bulkActions: {
    path: '',
    tags: {
      bulkActions: '',
    },
  },
  bulkUpload: {
    path: '',
    tags: {
      bulkUpload: '',
    },
  },
  bulkTransition: {
    path: '',
    tags: {
      bulkTransition: '',
    },
  },
  bulkUpdate: {
    path: '',
    tags: {
      bulkUpdate: '',
    },
  },
  configuration: {
    path: '',
    tags: {
      configuration: '',
    },
  },
  globalAttributes: {
    path: '',
    tags: {
      configuration: '',
      globalAttributes: '',
    },
  },
  workflow: {
    path: '',
    tags: {
      configuration: '',
      workflow: '',
    },
  },
  editWorkflow: {
    path: '',
    tags: {
      configuration: '',
      editWorkflow: '',
    },
  },
  templateList: {
    path: '',
    tags: {
      configuration: '',
      templateList: '',
    },
  },
  createTemplate: {
    path: '',
    tags: {
      configuration: '',
      templateList: '',
      createTemplate: '',
    },
  },
  editTemplate: {
    path: '',
    tags: {
      configuration: '',
      templateList: '',
      createTemplate: '',
      editTemplate: '',
    },
  },
  documentTemplate: {
    path: '',
    tags: {
      configuration: '',
      documentTemplate: '',
    },
  },
  dynamicChart: {
    path: '',
    tags: {
      configuration: '',
      dynamicChart: '',
    },
  },
  automation: {
    path: '',
    tags: {
      configuration: '',
      automation: '',
    },
  },
  createAutomation: {
    path: '',
    tags: {
      configuration: '',
      automation: '',
      createAutomation: '',
    },
  },
  updateAutomation: {
    path: '',
    tags: {
      configuration: '',
      automation: '',
      updateAutomation: '',
    },
  },
  themeAndAppearance: {
    path: '',
    tags: {
      configuration: '',
      themeAndAppearance: '',
    },
  },
  emailTemplate: {
    path: '',
    tags: {
      configuration: '',
      emailTemplate: '',
    },
  },
  helpAndSupport: {
    path: '',
    tags: {
      configuration: '',
      helpAndSupport: '',
    },
  },
  administrativeOptions: {
    path: '',
    tags: {
      administrativeOptions: '',
    },
  },
  userManagement: {
    path: '',
    tags: {
      userManagement: '',
      administrativeOptions: '',
    },
  },
  appManagement: {
    path: '',
    tags: {
      appManagement: '',
    },
  },
  documentInventory: {
    path: '',
    tags: {
      documentInventory: '',
    },
  },
  accessRequest: {
    path: '',
    tags: {
      accessRequest: '',
    },
  },
  editAccessRequest: {
    path: '',
    tags: {
      accessRequest: '',
      editAccessRequest: '',
    },
  },
};
