import React, { useCallback, useMemo } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { ALERT_LABEL } from '../../../const/CommonConst';

const AlertHealthIndicator = ({ flag }) => {
  const alertLabel = useMemo(() => {
    return ALERT_LABEL[flag] || flag;
  }, [flag]);

  const getAlertFlagColor = useCallback(
    (theme) => {
      switch (flag) {
        case 'Low':
          return theme?.palette?.success?.light;
        case 'Medium':
          return theme?.palette?.other?.modelPriorityMedium;
        case 'High':
          return theme?.palette?.error?.main;
        default:
          return theme?.palette?.text?.primary;
      }
    },
    [flag]
  );

  return (
    <Tooltip title={`${alertLabel}. Click to view overdue alerts`}>
      <CircleIcon
        fontSize="small"
        sx={{
          color: (theme) => getAlertFlagColor(theme),
        }}
      />
    </Tooltip>
  );
};
AlertHealthIndicator.propTypes = {
  flag: PropTypes.string.isRequired,
};

export default AlertHealthIndicator;
